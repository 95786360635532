import { IGetTokenTopHoldersParams } from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

export class TokenTopHoldersAPI {
  static getTopHolders = async ({
    chain,
    page = 1,
    page_size = 20,
    ordering = "",
    token_address,
  }: IGetTokenTopHoldersParams) => {
    return BaseAPI.callAPI({
      url: `/token/${token_address}/top_holders/`,
      params: {
        chain,
        page,
        page_size,
        ordering,
      },
    });
  };
}
