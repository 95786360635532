import { IGetSmartFlowsParams, IGetTotalFlowsParams } from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

interface ITokenStatistics {
  chain: string;
  num_hrs: number;
  category: string;
}

export class DexFlowsAPI {
  static getTotalFlows = async ({
    chain,
    page,
    page_size,
    ordering = "",
  }: IGetTotalFlowsParams) => {
    return BaseAPI.callAPI({
      url: "/dex/total_flows/",
      params: {
        chain,
        page,
        page_size,
        ordering,
      },
    });
  };

  static getSmartFlows = async ({
    chain,
    page,
    page_size,
    ordering = "",
    sm_wallet_category,
  }: IGetSmartFlowsParams) => {
    return BaseAPI.callAPI({
      url: "/dex/smart_flows/",
      params: {
        chain,
        page,
        page_size,
        ordering,
        sm_wallet_category,
      },
    });
  };

  static getTotalFlowsStatistics = async ({
    chain,
    num_hrs,
    category,
  }: ITokenStatistics) => {
    return BaseAPI.callAPI({
      url: "/dex/total_flows/stats/",
      params: {
        chain,
        num_hrs,
        category,
      },
    });
  };

  static getSmartFlowsStatistics = async ({
    chain,
    num_hrs,
    category,
  }: ITokenStatistics) => {
    return BaseAPI.callAPI({
      url: "/dex/smart_flows/stats/",
      params: {
        chain,
        num_hrs,
        category,
      },
    });
  };
}
