import { IGetSmartWhalesDexTxsParams } from "../../@types/api";
import { Chains } from "../constants";
import { BaseAPI } from "./BaseAPI";

export class SmartWhalesAPI {
  static getSmartWhaleCategories = async () => {
    return BaseAPI.callAPI({
      url: "/wallet/smart_money/categories/",
    });
  };

  static getDexTransactions = async ({
    chain,
    page = 1,
    page_size = 25,
    ordering = "",
    smart_whales_categories = [],
  }: IGetSmartWhalesDexTxsParams) => {
    return BaseAPI.callAPI({
      url: "/dex/smart_whales_transactions/",
      params: {
        chain: chain === Chains.All ? null : chain,
        page,
        page_size,
        ordering,
        smart_whales_categories:
          smart_whales_categories.length === 0 ? null : smart_whales_categories,
      },
    });
  };
}
