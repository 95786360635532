import { AuthHelpers } from "../helpers/auth";
import { LocalStorages } from "./localStorages";

export class RouteParams {
  static walletAddress = "wallet_address";
}

export class AppRoutes {
  static home = "/";
  static dashboard = "/dashboard";
  static resetPassword = "/password-reset/:code";
  static forgotPassword = "/forgot-password";
  static liveDexMaster = "/live-dex-master";
  static tokenGenius = "/token/:token_address";
  static tokenGeniusIndex = "/token-genius";
  static walletIndex = "/wallet-search";
  static walletProfile = "/wallet/:wallet_address";
  static profile = "/profile";
  static watchlist = "/watchlist";
  static smartAlert = "/smart-alerts";
  static createSmartAlert = "/smart-alerts/create";
  static createSmartAlertWithAddress = "/smart-alerts/:wallet_address/create";
  static updateSmartAlert = "/smart-alerts/:alert_id/update/";
  static smartDexStream = "/smart-dexstream";
  static smartHoldings = "/smart-holdings";
  static dexFlows = "/dex-flows";
  static leaderBoard = "/leaderboard";
  static whaleTrades = "/whale-trades";
  static narrativePulse = "/narrative-pulse";
  static perpGmxData = "/perp/gmx/data";
  static perpGmxTraderData = "/perp/gmx-trader";
  static perpOpenTrades = "/perp/open-trades";
  static disconnect = "/disconnect";
  static landing = "/access";
  static onchainAI = "/onchain-ai";
  static cexNetTransactions = "/cex-net-txns";
  static plan = "/plan";
  static research = "/research";
  static onchain101 = "/onchain-101";
  static aiAgentGenius = "/ai-agent-genius";

  static getRefCode = () => {
    const isLoggedIn = AuthHelpers.isUserLoggedIn();
    if (isLoggedIn) return LocalStorages.getRefCode();
    return new URLSearchParams(window.location.search).get("ref_code") ?? "";
  };

  static getSignupRoute = () => `/signup?ref_code=${AppRoutes.getRefCode()}`;
  static getWalletIndexRoute = () =>
    `/wallet-search?ref_code=${AppRoutes.getRefCode()}`;
  static getTokenGeniusIndexRoute = () =>
    `/token-genius?ref_code=${AppRoutes.getRefCode()}`;
  static getTokenGeniusRoute = (tokenAddress: string, chain: string) =>
    `/token/${tokenAddress}?chain=${chain}&ref_code=${AppRoutes.getRefCode()}`;
  static getTokenGeniusRouteWithoutRefCode = () => `/token/`;
  static getWalletProfileRoute = (
    walletAddress: string,
    chain: string,
    tab?: string
  ) =>
    `/wallet/${walletAddress}?chain=${chain}&ref_code=${AppRoutes.getRefCode()}` +
    (tab ? `&tab=${tab}` : "");
  static getWalletProfileRouteWithoutRefCode = () => `/wallet/`;
  static getSmartAlertUpdateRoute = (alertId: number) =>
    `/smart-alerts/${alertId}/update/`;
  static getCreateSmartAlertWitAddressRoute = (walletAddress = "") =>
    `/smart-alerts/${walletAddress}/create`;

  static getExactRoute = (route: string) => {
    return route;
  };
}
