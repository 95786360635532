import React from "react";
import { Outlet, useLocation } from "react-router";
import { AuthHelpers } from "../../helpers/auth";
import { SubScrType } from "../../utils/constants";
import { LocalStorages } from "../../utils/localStorages";
import { AppRoutes } from "../../utils/routes";
import LockedTab from "../Feature/LockedTab";

const ProtectedRoute: React.FC = () => {
  const location = useLocation();
  const isLoggedIn = AuthHelpers.isUserLoggedIn();

  const subScrType = isLoggedIn ? LocalStorages.getSubScrType() : 0;
  const route = location.pathname;

  if (
    route.startsWith(AppRoutes.plan) ||
    route.startsWith(AppRoutes.profile) ||
    route.startsWith(AppRoutes.leaderBoard) ||
    route.startsWith(AppRoutes.whaleTrades) ||
    route.startsWith(AppRoutes.watchlist)
  )
    if (subScrType < SubScrType.Starter) return <LockedTab route={route} />;

  if (
    route.startsWith(AppRoutes.perpGmxData) ||
    route.startsWith(AppRoutes.perpGmxTraderData) ||
    route.startsWith(AppRoutes.perpOpenTrades) ||
    route.startsWith(AppRoutes.smartAlert)
  )
    if (subScrType < SubScrType.Plus) return <LockedTab route={route} />;

  if (
    route.startsWith(AppRoutes.narrativePulse) ||
    route.startsWith(AppRoutes.smartDexStream) ||
    route.startsWith(AppRoutes.smartHoldings) ||
    route.startsWith(AppRoutes.dexFlows) ||
    route.startsWith(AppRoutes.onchainAI) ||
    route.startsWith(AppRoutes.cexNetTransactions)
  )
    if (subScrType < SubScrType.Nexus) return <LockedTab route={route} />;

  return <Outlet />;
};

export default ProtectedRoute;
