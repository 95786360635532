import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";
import { AppRoutes } from "../../utils/routes";

const AppHelmet = () => {
  const location = useLocation();
  const thumbnail = `https://platform.alphanomics.io/thumbnail${
    location.pathname === AppRoutes.aiAgentGenius ? "_agent" : ""
  }.png`;
  return (
    <Helmet>
      <meta property="og:image" content={thumbnail} />
      <meta name="twitter:image" content={thumbnail} />
    </Helmet>
  );
};

export default AppHelmet;
