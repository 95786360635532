import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { ITotalFlows, ITotalFlowsQuery } from "../../@types/redux";
import { DexFlowsAPI } from "../../utils/api/DexFlowsAPI";

export const totalFlowsDefaultQuery = {
  chain: "",
  page_size: 20,
  page: 1,
  ordering: "-volume_1day",
};

const initialState = {
  isRefreshing: false,
  loading: false,
  total_count: 0,
  total_page: 0,
  results: [],
  query: { ...totalFlowsDefaultQuery },
};

const totalFlowsSlice = createSlice({
  name: "totalFlows",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<ITotalFlowsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.results = [];
      }
    },

    setList: (state, action: PayloadAction<ITotalFlows>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total_count = action.payload.total_count;
      state.total_page = action.payload.total_page;
      state.results = action.payload.results;
    },

    setQuery: (state, action: PayloadAction<ITotalFlowsQuery>) => {
      state.query.page_size = action.payload.page_size;
      state.query.page = action.payload.page;
      state.query.ordering = action.payload.ordering;
      state.query.chain = action.payload.chain;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = {
        ...totalFlowsDefaultQuery,
      };
    },
  },
});

const totalFlowsAction = totalFlowsSlice.actions;
const totalFlowsReducer = totalFlowsSlice.reducer;

export { totalFlowsAction, totalFlowsReducer };

// Sagas

function* getTotalFlows(action: PayloadAction<ITotalFlowsQuery>): any {
  const res = yield call(DexFlowsAPI.getTotalFlows as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      totalFlowsAction.setList({
        ...res.data,
      })
    );
  } else {
    yield put(
      totalFlowsAction.setList({
        total_count: 0,
        total_page: 0,
        results: [],
      })
    );
  }
}

export function* totalFlowsSaga() {
  yield all([takeLatest(totalFlowsAction.getList, getTotalFlows)]);
}
